var notification = $ ('.notification');
if (notification.length) {

	notification.click (function () {
		$ (this).fadeOut ('fast');
	});

	setTimeout (function () {
		notification.fadeOut (1500)
	}, 5 * 1000)
}