var previousTitle, previousMessage;

$ ('.confirm').click (function (e) {

	var titleElement = $ ('#confirmation-title');
	var messageElement = $ ('#confirmation-message');

	previousTitle = titleElement.text ();
	previousMessage = messageElement.text ();

	var confirmTitle = $ (this).data ('title');
	var confirmMessage = $ (this).data ('message');

	var tag = $ (this).prop ('tagName');
	if (tag === 'A') {
		var href = $ (this).attr ('href');

		$ ('#confirm-button').attr ('href', href);
	} else if (tag === 'BUTTON') {
		var form = $ (this).closest ('form');
		$ ('#confirm-button').click (function () {
			form.submit ();
		})
	}

	if (confirmTitle)
		titleElement.text (confirmTitle);

	if (confirmMessage)
		messageElement.text (confirmMessage);

	$ ('#confirm-modal').modal ('show');

	$ ('.main-wrapper').addClass ('blur');

	e.preventDefault ();
});

$ ('#confirm-modal').on ('hidden.bs.modal', function () {
	$ ('#confirmation-title').text (previousTitle);
	$ ('#confirmation-message').text (previousMessage);
	$ ('.main-wrapper').removeClass ('blur');
});