/**
 * Makes slug from input string
 *
 * @param text
 * @param trimSpaces
 *
 * @returns {string}
 */
function slugify(text, trimSpaces) {
	text = text.toString ().toLowerCase ();

	if (trimSpaces)
		text = text.trim ();

	return text.replace (/ /g, '-')
		.replace (/[àáâãäå]/g, "a")
		.replace (/[čç]/g, "c")
		.replace (/[ěèéêë]/g, "e")
		.replace (/[ùúûüů]/g, "u")
		.replace (/[ìíîï]/g, "i")
		.replace (/[ňńñ]/g, "n")
		.replace (/[òóôõö]/g, "o")
		.replace (/[ýÿ]/g, "y")
		.replace (/œ/g, "oe")
		.replace (/ď/g, "d")
		.replace (/ř/g, "r")
		.replace (/š/g, "s")
		.replace (/ž/g, "z")
		.replace (/ť/g, "t")
		.replace (/æ/g, "ae")
		.replace (/[^a-z0-9\-]+/g, '')
		.replace (/--+/g, '-');
}

$ ('#lockUrl').change (function () {
	$ ('.slug-source').keyup ();
});

$ ('.slug-target').keyup (function () {
	$ (this).val (slugify ($ (this).val (), false));
	$ ('#lockUrl').prop ('checked', true);
});

$ ('.slug-source').keyup (function () {
	var source = $ (this);
	var target = $ ('.slug-target');
	var locked = $ ('#lockUrl');

	if (!locked.is (':checked'))
		target.val (slugify (source.val (), true));
});
