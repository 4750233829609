// bind some custom methods
$ (document).ready (function () {

	$ ("body").addClass ("loaded");

	$ ('[data-toggle="tooltip"]').tooltip ()

	// find if any error occured and mark tab with that error
	$ ('.has-error').each (function () {
		var error = $ (this);
		var tabCard = error.closest ('.tab-pane');

		$ ("#" + tabCard.prop ('id') + "-tab").addClass ('tab-with-error');
	});

	$ ('#removeTitlePhoto').click (function () {
		$ ('#titlePhotoContainer').remove ();
	});

	$ ('form').areYouSure ();
});

$ ('.date').datepicker ({
	format: 'dd. mm. yyyy'
});

$ ('.checkbox').change (function () {
	var e = $ (this);
	var checked = e.is (':checked');
	var id = e.data ('id');

	$ ('.child-' + id).prop ('checked', checked);

	// check also parent
	if (e.hasClass ('child-checkbox') && checked) {
		var parent = e.data ('parent');
		$ ('#checkbox-parent-' + parent).prop ('checked', true);
	}
});

$ ('.has-subpages').click (function (e) {
	if (!$ (e.target).is ("div"))
		return;

	var id = $ (this).data ('id');
	$ ('.parent-page-' + id).toggleClass ('hidden-xs-up');
})